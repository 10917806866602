class Accordion {
  constructor(selector) {
    this.accordion = selector;
    this.button = this.accordion;
    this.contentToReveal = this.button.nextElementSibling;
    this.init();
  }

  init() {
    this.button.addEventListener('click', (e) => {
      e.preventDefault();
      this.handleAccordion();
    });
  }

  handleAccordion() {
    if (this.contentToReveal.style.maxHeight) {
      this.hideText();
    } else {
      this.showText();
    }
  }

  showText() {
    this.accordion.classList.add('is-visible');
    this.contentToReveal.classList.add('is-visible');
    this.contentToReveal.style.maxHeight = `${this.contentToReveal.scrollHeight}px`;
  }

  hideText() {
    this.accordion.classList.remove('is-visible');
    this.contentToReveal.classList.remove('is-visible');
    this.contentToReveal.style.maxHeight = null;
  }
}

export default Accordion;
